<template>
  <div>
    <div class="head_box">
      <div class="img_box">
        <img src="../assets/images/contact_head.png" alt="" />
      </div>
      <div class="head_content">
        <div class="flex head_content_box">
          <div class="flex-center left">
            <div>
              <div class="left_item left_item1">
                <div class="flex-items-center left_title">
                  <img class="icon" src="../assets/images/icon_1.png" alt="" />
                  商务合作
                </div>
                <div class="left_content">bd@01bite.com</div>
              </div>
              <div class="left_item left_item1">
                <div class="flex-items-center left_title">
                  <img class="icon" src="../assets/images/icon_2.png" alt="" />
                  招聘相关
                </div>
                <div class="left_content">hr@01bite.com</div>
              </div>
              <div class="left_item">
                <div class="flex-items-center left_title">
                  <img class="icon" src="../assets/images/icon_3.png" alt="" />
                  公司地址
                </div>
                <a :href=linkUrl>
                <div @click="handlClick(1)" class="w-262px left_content">
                  浙江省杭州市西湖区天堂软件园E幢14层
                </div>
              </a>
              </div>
            </div>
          </div>
          <div class="right">
            <a :href=linkUrl>
              <img src="../assets/images/map_pic.png" alt="" />
            </a>
          </div>
        </div>
        <div class="partners_container">
          <div class="title">合作伙伴</div>
          <div class="mb-50px flex-center partners_box">
            <img src="../assets/images/contact_1.png" alt="" />
            <img src="../assets/images/contact_2.png" alt="" />
            <img src="../assets/images/contact_3.png" alt="" />
            <img src="../assets/images/contact_4.png" alt="" />
          </div>
          <div class="flex-center partners_box">
            <img src="../assets/images/contact_5.png" alt="" />
            <img src="../assets/images/contact_6.png" alt="" />
            <img src="../assets/images/contact_7.png" alt="" />
            <img src="../assets/images/contact_8.png" alt="" />
          </div>
        </div>
      </div>
      
      
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      //定义起点位置
      StartXY: {
        lng: 116.327404,
        lat: 39.971385,
        city: '北京市',
      },
      //定义终点位置
      EndXY: {
        lng: 116.545123,
        lat: 39.915599,
        city: '北京市',
      },
      linkUrl: "http://uri.amap.com/marker?position=120.124108,30.295859&name=天堂软件园E幢&coordinate=gaode&callnative=1"
    }
  },
  methods: {
   
  },
}
</script>

<style lang="less" scoped>
.head_box {
  @media (max-width: 700px) {
    // margin-bottom: 120%;
    // height: 1.8rem;
    min-height: calc(100vh - 0.79rem);
  }
  width: 100%;
  position: relative;
  // margin-bottom: 10%;
  
  min-height: calc(100vh - 2.79rem);
}
.img_box {
  width: 100%;
  > img {
    width: 100%;
    height: 100%;
  }
}
.head_content {

  @media (max-width: 1500px) {
    // bottom: -4rem;
  }
  @media (max-width: 700px) {
    width: 86.93%;
    // height: 2rem;
    // bottom: -1.71rem;
  }
  width: 70%;
  // height: 8.27rem;
  background: #ffffff;
  //position: absolute;
  // bottom: -80%;
  //top: 80%;
  //left: 50%;
  transform: translateY(-10%);
  margin: 0 auto;
  // overflow: hidden;
}
.head_content_box {
  @media (max-width: 700px) {
    box-shadow: 0px 2px 7px 0px rgba(75,148,223,0.15);
  }
  box-shadow: 0px 10px 30px 0px rgba(75, 148, 223, 0.15);

}
.icon {
  @media (max-width: 700px) {
    width: 0.12rem;
    height: 0.12rem;
  }
  width: 0.21rem;
  height: 0.21rem;
  margin-right: 0.03rem;
}
.left {
  @media (max-width: 1500px) {
    padding: 0 0.8rem 0 0.5rem;
  }
  @media (max-width: 900px) {
    font-size: 0.12rem;
    // width: 1.31rem;
    padding: 0.12rem;
    box-sizing: border-box;
  }
  width: 40%;
  // height: 100%;
  font-size: 0.2rem;
  padding: 0 1.2rem 0 1rem;
  // padding: 0.2rem 1.17rem ;
  div {
    width: 100%;
  }
}
.left_item1 {
  @media (max-width: 700px) {
    margin-bottom: 0.12rem;
  }
  margin-bottom: 0.42rem;
}
.right {
  width: 60%;
  height: 100%;
  img {
    width: 100%;
  }
}
.left_title {
  font-weight: 600;
  color: #183266;
}
.left_content {
  @media (max-width: 700px) {
    font-size: 0.2rem;
    zoom: 0.5;
  }
  color: #79849b;
  font-weight: normal;
  margin-left: 24px;
}
.title {
  @media (max-width: 1500px) {
    // margin-top: 5.5rem;
    margin-bottom: 0.5rem;
  }
  @media (max-width: 700px) {
    font-size: 0.2rem;
    margin-top: 0.24rem;
    // margin-top: 2.15rem;
    margin-bottom: 0.12rem;
  }
  font-size: 0.35rem;
  font-family: 'AlimamaShuHeiTi-Bold';
  color: #183266;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.8rem;
}
.partners_box {
  img {
    @media (max-width: 1500px) {
      width: 2rem;
    }
    @media (max-width: 700px) {
      width: 0.81rem;
    }
    width: 340px;
  }
}
.mb-50px {
  @media (max-width: 700px) {
      margin-bottom: 0.03rem;
    }
}
.partners_container {
  @media (max-width: 700px) {
    width: 100%;
    // margin-bottom: 1.6rem;
  }
  width: 100%;
  // margin: 0 auto 2rem;
  img {
    width: 25%;
  }
}

</style>
